import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import uniqid from 'uniqid';
import Case from 'case';

import { Box, Text } from 'grommet';

import { CurrentDateContext } from '@Components/Context';
import { withProductAuth } from '@Components/Layout';
import {
  Seo, RadioButtonSlider, AppButton, Spinning, UnderConstruction,
} from '@Components/Control';
import { AuthNavWrapper } from '@Components/Navigation';
import {
  ProductFormFieldContainer,
  ProductFormDropdownSelect,
  ProductFormSectionWrapper,
} from '@Components/Partial/Product/NewSearch';

import {
  initiateNewFormConfigRequest,
  submitNewSearchRequest,
  initiateNewBulkFormConfigRequest,
  submitNewBulkSearchRequest,
} from '@Actions';
import { paths } from '@Components/configs';

import DynamicFormRenderer from './DynamicFormRenderer';


const BannerChildren = ({
  bgColor, textColor, setBulkSearch,
}) => (
  <Box direction="row" justify="between">
    <RadioButtonSlider
      radioOptions={[{ label: 'Individual', value: 'individual' }, { label: 'Bulk search', value: 'bulk' }]}
      componentBg={bgColor}
      textColor={textColor}
      activeButtonBg="white"
      updateParent={setBulkSearch}
    />
  </Box>
);

BannerChildren.propTypes = {
  bgColor: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  setBulkSearch: PropTypes.func.isRequired,
};

const FormContents = ({
  small,
  loading,
  underMaintenance,
  authPagesConfig,
  config,
  searchTypeDisplay,
  requiredFormInputs,
  optionalFormInputs,
  formValues,
  handleSearchType,
  formSubmitting,
  handleFormValues,
  today,
  useNextDay,
  minStartDate,
  searchTypeKey,
  resetForm,
  submitEnabled,
  handleFormSubmit,
}) => {
  if (underMaintenance) {
    return (
      <Box flex align="center" justify="center" background="white">
        <UnderConstruction
          imageUrl={authPagesConfig.constructionImage}
          buttonHighlight={authPagesConfig.buttonHighlight}
          header="Under Maintenance"
          message="System currently undergoing maintenance. Please check back later."
        />
      </Box>
    );
  }

  if (loading) {
    return (
      <Box flex align="center" justify="center" background="white">
        <Spinning size="large" color={authPagesConfig.buttonHighlight} />
      </Box>
    );
  }

  return (
    <Box flex direction={small ? 'column' : 'row'} gap="2rem" justify={small ? 'start' : 'evenly'} pad={small ? '1.5rem' : '2rem'}>
      <ProductFormSectionWrapper
        small={small}
        sectionBg={authPagesConfig.altComponentBg}
        sectionTitle="Required Fields"
        titleColor={authPagesConfig.primaryText}
        borderColor={authPagesConfig.navBorder}
      >
        {Array.isArray(config?.searchTypes) && config.searchTypes.length > 1 && (
          <ProductFormFieldContainer
            small={small}
            fieldTitle="Search Type"
            primaryText={authPagesConfig.primaryText}
            hintText={authPagesConfig.hintText}
            borderColor={authPagesConfig.navBorder}
          >
            <ProductFormDropdownSelect
              selectable
              autoSelect
              authPagesConfig={authPagesConfig}
              label={searchTypeDisplay}
              handleFormValues={(searchTypeName) => handleSearchType(searchTypeName)}
              options={config && config.searchTypes}
            />
          </ProductFormFieldContainer>
        )}
        {Array.isArray(requiredFormInputs) && requiredFormInputs.map((dt) => (
          <DynamicFormRenderer
            key={dt.key}
            resetKey={formValues.resetKey}
            searchTypeKey={searchTypeKey}
            fieldData={dt}
            small={small}
            handleFormValues={(value, key) => handleFormValues(value, key)}
            formValues={formValues.searchFieldValues}
            authPagesConfig={authPagesConfig}
            today={today}
            useNextDay={useNextDay}
            minStartDate={minStartDate}
          />
        ))}
      </ProductFormSectionWrapper>
      <Box gap="2rem" width={{ min: '47%' }} direction="column">
        <ProductFormSectionWrapper
          small={small}
          sectionBg={authPagesConfig.altComponentBg}
          sectionTitle="Optional Fields"
          titleColor={authPagesConfig.primaryText}
          borderColor={authPagesConfig.navBorder}
        >
          {(Array.isArray(optionalFormInputs) && optionalFormInputs?.length > 0) ? (
            optionalFormInputs.map((dt) => (
              <DynamicFormRenderer
                key={dt.key}
                resetKey={formValues.resetKey}
                searchTypeKey={searchTypeKey}
                fieldData={dt}
                small={small}
                handleFormValues={(value, key) => handleFormValues(value, key)}
                formValues={formValues.searchFieldValues}
                authPagesConfig={authPagesConfig}
                today={today}
                useNextDay={useNextDay}
                minStartDate={minStartDate}
              />
            ))) : (
              <Box width="100%" align="center" justify="center" pad={{ top: '1rem' }}>
                <Text size="1rem" color="#A2A2A2" textAlign="center">
                  No optional inputs for current selections
                </Text>
              </Box>
          )}
        </ProductFormSectionWrapper>
        <Box direction="row" gap="1rem" justify="center">
          <AppButton
            width="6.5rem"
            onClick={() => resetForm()}
            level="dynamicLarge"
            color={authPagesConfig.buttonHighlight}
            fontWeight={600}
            label="Cancel"
          />
          <AppButton
            overrideHover
            disabled={!submitEnabled || formSubmitting}
            onClick={() => ((submitEnabled && !formSubmitting) ? handleFormSubmit() : null)}
            level="dynamicLarge"
            color="white"
            bgColor={authPagesConfig.buttonHighlight}
            fontWeight={600}
            label="Start Analysis"
          />
        </Box>
      </Box>
    </Box>
  );
};

FormContents.propTypes = {
  small: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  underMaintenance: PropTypes.bool.isRequired,
  authPagesConfig: PropTypes.shape({
    pageBg: PropTypes.string.isRequired,
    altComponentBg: PropTypes.string.isRequired,
    navBorder: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    hintText: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    selectButtonBg: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    focusHighlight: PropTypes.string.isRequired,
    iconHighlightColor: PropTypes.string.isRequired,
    incrementText: PropTypes.string.isRequired,
    decrementText: PropTypes.string.isRequired,
    disableSearchFeatures: PropTypes.bool.isRequired,
    constructionImage: PropTypes.string.isRequired,
  }),
  config: PropTypes.shape({
    searchTypes: PropTypes.arrayOf(PropTypes.shape({
      category: PropTypes.string,
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      fieldData: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string.isRequired,
        order: PropTypes.number.isRequired,
        config: PropTypes.objectOf(PropTypes.any),
        required: PropTypes.bool,
        fieldName: PropTypes.string.isRequired,
        fieldType: PropTypes.string.isRequired,
        selectable: PropTypes.bool,
        description: PropTypes.string,
      }).isRequired).isRequired,
    })),
  }),
  searchTypeDisplay: PropTypes.string,
  requiredFormInputs: PropTypes.arrayOf(PropTypes.any),
  optionalFormInputs: PropTypes.arrayOf(PropTypes.any),
  formValues: PropTypes.shape({
    searchType: PropTypes.string,
    searchFieldValues: PropTypes.objectOf(PropTypes.any),
    resetKey: PropTypes.string,
  }),
  handleSearchType: PropTypes.func.isRequired,
  formSubmitting: PropTypes.bool.isRequired,
  handleFormValues: PropTypes.func.isRequired,
  today: PropTypes.instanceOf(Date),
  useNextDay: PropTypes.bool.isRequired,
  minStartDate: PropTypes.string,
  searchTypeKey: PropTypes.string,
  resetForm: PropTypes.func.isRequired,
  submitEnabled: PropTypes.bool,
  handleFormSubmit: PropTypes.func.isRequired,
};

const NewSearchPage = ({
  small,
  mixpanel,
  location,
  loading,
  submitLoading,
  fetchFormConfig,
  submitNewSearch,
  fetchBulkFormConfig,
  submitNewBulkSearch,
  formConfig = null,
  bulkFormConfig = null,
  authPagesConfig = null,
  customReports = null,
  cyclopsConfig = null,
}) => {
  const [bulkSearch, setBulkSearch] = React.useState(false);
  const [searchType, setSearchType] = React.useState(null);
  const [useConfig, setUseConfig] = React.useState(null);
  const [searchTypeKey, setSearchTypeKey] = React.useState(null);
  const [formConfigResult, setFormConfigResult] = React.useState(null);
  const [requiredFormInputs, setRequiredFormInputs] = React.useState(null);
  const [optionalFormInputs, setOptionalFormInputs] = React.useState(null);
  const [formSubmitting, setFormSubmitting] = React.useState(false);
  const [configLoading, setConfigLoading] = React.useState(true);
  const [formValues, setFormValues] = React.useState({
    searchType: null, searchFieldValues: {}, resetKey: uniqid(),
  });
  const { today } = React.useContext(CurrentDateContext);
  const underMaintenance = authPagesConfig && authPagesConfig.disableSearchFeatures;

  React.useEffect(() => {
    fetchFormConfig();
    fetchBulkFormConfig();
  }, []);

  React.useEffect(() => {
    if (bulkSearch && bulkFormConfig) {
      setUseConfig(bulkFormConfig);
      setSearchType(bulkFormConfig.searchTypes[0].name);
    } else if (formConfig) {
      setUseConfig(formConfig);
      setSearchType(formConfig.searchTypes[0].name);
    }
  }, [formConfig, bulkFormConfig, bulkSearch]);

  React.useEffect(() => {
    if (useConfig && searchType) {
      setFormConfigResult(_.find(useConfig.searchTypes, { name: searchType }).fieldData);
      setFormValues({ searchType, searchFieldValues: {}, resetKey: uniqid() });
      setSearchTypeKey(Case.camel(_.find(useConfig.searchTypes, { name: searchType })?.category));
    }
  }, [searchType, bulkSearch, useConfig]);

  React.useEffect(() => {
    if (formConfigResult) {
      const partitionedResults = _.partition(formConfigResult, 'required');
      const [requiredInputs, optionalInputs] = partitionedResults;

      const filteredRequiredInputs = _.filter(requiredInputs, (({ config }) => (
        !config?.supportedCategoryPlatforms && !config?.supportedRecurrence)));
      const filteredOptionalInputs = _.filter(optionalInputs, (({ config }) => (
        !config?.supportedCategoryPlatforms && !config?.supportedRecurrence)));

      setRequiredFormInputs(filteredRequiredInputs);
      setOptionalFormInputs(filteredOptionalInputs);
      setConfigLoading(false);
    }
  }, [formConfigResult]);

  const checkInputRequirements = (config) => {
    const selectedPlatforms = formValues.searchFieldValues.platform;
    const selectedRecurrence = formValues.searchFieldValues.recurrence;
    const currentPlatformConfig = config?.supportedCategoryPlatforms
      && config.supportedCategoryPlatforms[searchTypeKey];

    if (currentPlatformConfig && config?.supportedRecurrence) {
      return selectedPlatforms?.some((p) => currentPlatformConfig.includes(p))
        && (config.supportedRecurrence === selectedRecurrence);
    }

    if (currentPlatformConfig) {
      return selectedPlatforms?.some((p) => currentPlatformConfig.includes(p));
    }

    if (config?.supportedRecurrence) {
      return (config.supportedRecurrence === selectedRecurrence);
    }

    return true;
  };

  React.useEffect(() => {
    if (formConfigResult) {
      const partitionedResults = _.partition(formConfigResult, 'required');
      const [requiredInputs, optionalInputs] = partitionedResults;

      const filteredRequiredInputs = _.filter(requiredInputs, (({ config }) => (
        checkInputRequirements(config))));
      const filteredOptionalInputs = _.filter(optionalInputs, (({ config }) => (
        checkInputRequirements(config))));

      setRequiredFormInputs(filteredRequiredInputs);
      setOptionalFormInputs(filteredOptionalInputs);
    }
  }, [formValues.searchFieldValues]);

  React.useEffect(() => {
    if (requiredFormInputs && optionalFormInputs) {
      const requiredKeys = requiredFormInputs.map(({ key }) => key);
      const optionalKeys = optionalFormInputs.map(({ key }) => key);
      const selectedKeys = Object.keys(formValues.searchFieldValues);
      const unsupportedKeys = selectedKeys?.filter((k) => (
        !requiredKeys.includes(k) && !optionalKeys.includes(k)));

      if (unsupportedKeys?.length > 0) {
        const updatedValues = { ...formValues.searchFieldValues };

        unsupportedKeys.forEach((key) => {
          delete updatedValues[key];
        });

        setFormValues({ ...formValues, searchFieldValues: updatedValues });
      }
    }
  }, [requiredFormInputs, optionalFormInputs]);

  const handleFormValues = (value, key) => {
    const updatedValues = { ...formValues.searchFieldValues };

    if (!value || value?.length === 0) {
      delete updatedValues[key];
      setFormValues({ ...formValues, searchFieldValues: updatedValues });
      return;
    }

    updatedValues[key] = value;
    setFormValues({ ...formValues, searchFieldValues: updatedValues });
  };

  const handleSearchType = (searchTypeName) => {
    setFormValues({ ...formValues, searchFieldValues: {}, resetKey: uniqid() });
    setSearchType(searchTypeName);
  };

  const resetForm = () => setFormValues({ searchFieldValues: {}, resetKey: uniqid() });

  const handleBulkSelect = () => {
    resetForm();
    setBulkSearch(!bulkSearch);
  };

  let minStartDate = null;

  const searchTypeDisplay = useConfig && (
    searchType
      ? _.find(useConfig.searchTypes, { name: searchType }).name
      : useConfig.searchTypes[0].name
  );
  const searchTypeId = useConfig && (
    searchType
      ? _.find(useConfig.searchTypes, { name: searchType }).id
      : useConfig.searchTypes[0].id
  );

  if (formValues.searchFieldValues?.start_date_and_time) {
    minStartDate = formValues.searchFieldValues.start_date_and_time;
  }

  const requiredKeys = Array.isArray(requiredFormInputs)
    && requiredFormInputs.map(({ key }) => key);
  const submitEnabled = requiredKeys && requiredKeys.every((key) => (
    key in formValues.searchFieldValues
    && (formValues.searchFieldValues[key].length > 0
    || formValues.searchFieldValues[key] !== null)
  ));

  /* eslint-disable camelcase */
  const handleFormSubmit = () => {
    setFormSubmitting(true);

    if (bulkSearch) {
      submitNewBulkSearch(
        {
          search: {
            search_type: formValues.searchType,
            search_type_id: searchTypeId,
            search_field_values: formValues.searchFieldValues,
          },
        },
        paths.productListSearches.replace(':status', 'live'),
        () => resetForm(),
      );
    } else {
      submitNewSearch(
        {
          search: {
            search_type: formValues.searchType,
            search_type_id: searchTypeId,
            name: null,
            search_field_values: formValues.searchFieldValues,
          },
        },
        paths.productListSearches.replace(':status', 'live'),
        () => resetForm(),
      );
    }

    setFormSubmitting(false);
  };
  /* eslint-enable camelcase */

  const pageTitle = bulkSearch ? 'Create bulk searches' : 'Create a new search';

  return (
    <AuthNavWrapper
      small={small}
      mixpanel={mixpanel}
      location={location}
      authPagesConfig={authPagesConfig}
      customReports={customReports}
      cyclopsConfig={cyclopsConfig}
      bannerProps={{
        title: pageTitle,
        subTitle: searchTypeDisplay ? `Blacklight - ${searchTypeDisplay}` : 'Blacklight',
        textColor: 'white',
        bannerChildrenPosition: 'bottom',
        bannerChildren: bulkFormConfig?.searchTypes?.length > 0 && (
          <BannerChildren
            bgColor="#F2F2F7"
            textColor={authPagesConfig.primaryText}
            setBulkSearch={() => handleBulkSelect()}
          />
        ),
      }}
    >
      <Seo />
      <FormContents
        small={small}
        key={bulkSearch ? 'bulk' : 'individual'}
        loading={loading || submitLoading || configLoading}
        authPagesConfig={authPagesConfig}
        underMaintenance={underMaintenance}
        config={useConfig}
        searchTypeDisplay={searchTypeDisplay}
        requiredFormInputs={requiredFormInputs}
        optionalFormInputs={optionalFormInputs}
        formValues={formValues}
        handleSearchType={handleSearchType}
        formSubmitting={formSubmitting}
        handleFormValues={handleFormValues}
        today={today}
        useNextDay={bulkSearch}
        minStartDate={minStartDate}
        searchTypeKey={searchTypeKey}
        resetForm={resetForm}
        submitEnabled={submitEnabled}
        handleFormSubmit={handleFormSubmit}
      />
    </AuthNavWrapper>
  );
};

function mapStateToProps(state) {
  return {
    formConfig: state.productNewForm.formConfig,
    bulkFormConfig: state.productNewForm.bulkFormConfig,
    loading: state.fetchLoader.dataLoading,
    submitLoading: state.fetchLoader.submissionLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchFormConfig: initiateNewFormConfigRequest,
    submitNewSearch: submitNewSearchRequest,
    fetchBulkFormConfig: initiateNewBulkFormConfigRequest,
    submitNewBulkSearch: submitNewBulkSearchRequest,
  }, dispatch);
}

NewSearchPage.propTypes = {
  small: PropTypes.bool.isRequired,
  mixpanel: PropTypes.shape({
    track: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  submitLoading: PropTypes.bool.isRequired,
  fetchFormConfig: PropTypes.func.isRequired,
  submitNewSearch: PropTypes.func.isRequired,
  fetchBulkFormConfig: PropTypes.func.isRequired,
  submitNewBulkSearch: PropTypes.func.isRequired,
  formConfig: PropTypes.shape({
    searchTypes: PropTypes.arrayOf(PropTypes.shape({
      category: PropTypes.string,
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      fieldData: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string.isRequired,
        order: PropTypes.number.isRequired,
        config: PropTypes.objectOf(PropTypes.any),
        required: PropTypes.bool,
        fieldName: PropTypes.string.isRequired,
        fieldType: PropTypes.string.isRequired,
        selectable: PropTypes.bool,
        description: PropTypes.string,
      }).isRequired).isRequired,
    })),
  }),
  bulkFormConfig: PropTypes.shape({
    searchTypes: PropTypes.arrayOf(PropTypes.shape({
      category: PropTypes.string,
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      fieldData: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string.isRequired,
        order: PropTypes.number.isRequired,
        config: PropTypes.objectOf(PropTypes.any),
        required: PropTypes.bool,
        fieldName: PropTypes.string.isRequired,
        fieldType: PropTypes.string.isRequired,
        selectable: PropTypes.bool,
        description: PropTypes.string,
      }).isRequired).isRequired,
    })),
  }),
  authPagesConfig: PropTypes.shape({
    pageBg: PropTypes.string.isRequired,
    altComponentBg: PropTypes.string.isRequired,
    navBorder: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    hintText: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    selectButtonBg: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    focusHighlight: PropTypes.string.isRequired,
    iconHighlightColor: PropTypes.string.isRequired,
    incrementText: PropTypes.string.isRequired,
    decrementText: PropTypes.string.isRequired,
    disableSearchFeatures: PropTypes.bool.isRequired,
  }),
  customReports: PropTypes.objectOf(PropTypes.any),
  cyclopsConfig: PropTypes.arrayOf(PropTypes.any),
};

export default connect(mapStateToProps, mapDispatchToProps)(withProductAuth(NewSearchPage));
