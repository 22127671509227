import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import Case from 'case';

import { Box, Text } from 'grommet';

import {
  Seo, AppButton, UnderConstruction,
} from '@Components/Control';
import { AuthNavWrapper } from '@Components/Navigation';
import { WidgetRenderer, SearchDetails } from '@Components/Partial/Product/Dashboard';
import { withProductAuth } from '@Components/Layout';
import {
  initiateProductDashboardSearchResultsRequest,
  clearProductDashboardSearchResultsRequest,
  updateProductDashboardReviewSelectionsRequest,
  initiateProductDashboardSearchResultsMetaRequest,
  clearProductDashboardSearchResultsMetaRequest,
} from '@Actions';


const DashboardResultsPage = ({
  small,
  mixpanel,
  location,
  size,
  authPagesConfig = null,
  customReports = null,
  loading,
  fetchSearchResultsData,
  clearSearchResultsData,
  searchResultsData = null,
  updateSearchResultsReviewSelections,
  fetchSearchResultsMeta,
  clearSearchResultsMeta,
  searchResultsMeta = null,
  cyclopsConfig = null,
}) => {
  const { id: searchId } = useParams();
  const [reviewedData, setReviewedData] = React.useState([]);
  const [initialLaterCount, setInitialLaterCount] = React.useState(null);
  const [query, setQuery] = React.useState({
    id: searchId,
    paging: {
      to: 1,
      size: 10,
    },
  });

  React.useEffect(() => {
    fetchSearchResultsData(searchId, { ...query });
    fetchSearchResultsMeta(searchId);

    return () => {
      clearSearchResultsData();
      clearSearchResultsMeta();
    };
  }, [query]);

  const handleQuery = (parentKey, updateKey, value) => {
    const prevQuery = { ...query };

    if (parentKey && !updateKey) {
      prevQuery[parentKey] = value;
    } else {
      prevQuery[parentKey][updateKey] = value;
    }

    setQuery(prevQuery);
  };

  const handleSort = (sortValue, sortDirection) => {
    const prevQuery = { ...query };

    if (!prevQuery.sort && sortValue) {
      prevQuery.sort = {};
    }

    if (sortValue === null && prevQuery.sort) {
      delete prevQuery.sort;
    } else if (sortValue) {
      prevQuery.sort = {
        name: sortValue,
        direction: sortDirection,
      };
    }

    setQuery(prevQuery);
  };

  const handleFilter = (
    filterKey, filterValue, filterPredicate, seperateValues = false, reset = false,
  ) => {
    const prevQuery = { ...query };

    if (reset) {
      delete prevQuery.filters;
      return setQuery(prevQuery);
    }

    if (!prevQuery.filters && filterValue) {
      prevQuery.filters = [];
    }

    const filterIndex = _.findIndex(prevQuery.filters, ['name', filterKey]);

    if (!filterValue && prevQuery.filters) {
      delete prevQuery.filters[filterIndex];
    } else if (filterValue && !seperateValues) {
      const newFilter = {
        name: filterKey,
        value: filterValue,
        predicate: filterPredicate,
      };

      if (filterIndex !== -1) {
        prevQuery.filters[filterIndex] = { ...newFilter };
      } else {
        prevQuery.filters.push(newFilter);
      }
    } else if (filterValue && seperateValues) {
      const addFilters = prevQuery.filters.concat(filterValue);
      prevQuery.filters = addFilters;
    }

    if (prevQuery.filters && prevQuery.filters.length === 0) {
      delete prevQuery.filters;
    }

    return setQuery(prevQuery);
  };

  /* eslint-disable camelcase */
  const initializeReviewedRows = (data) => {
    const reviewDataArr = data.map((dt) => {
      const reviewable = _.find(dt, ((row) => row.decoration === 'editable/dropdown'));
      const id = reviewable?.editableId;
      const api = reviewable?.api;

      return { id, review_status: api };
    });

    const filteredReviewable = _.filter(reviewDataArr, ((dt) => dt.id !== undefined));
    const laterCount = filteredReviewable && _.filter(filteredReviewable, (({ review_status }) => review_status === 'later')).length;
    setInitialLaterCount(laterCount);
    setReviewedData(filteredReviewable);
  };

  const updateRowReviewStatus = (editableId, selectedOption) => {
    const reviewDataArr = [...reviewedData];
    const editSelectionIndex = _.findIndex(reviewDataArr, { id: editableId });

    if (editSelectionIndex !== -1) {
      reviewDataArr[editSelectionIndex].review_status = selectedOption;
      setReviewedData(reviewDataArr);
    }
  };

  const sendReviewedResults = () => {
    const filteredSelections = _.filter(reviewedData, (({ review_status }) => review_status !== 'pending'));
    updateSearchResultsReviewSelections({
      data: filteredSelections, search_result_can_be_updated: true,
    }, () => {
      fetchSearchResultsData(searchId, { ...query });
    });
    clearSearchResultsData();
    setReviewedData([]);
    window.scrollTo(0, 0);
  };

  const laterCount = reviewedData && _.filter(reviewedData, (({ review_status }) => review_status === 'later')).length;
  let submitEnabled = reviewedData.some(({ review_status }) => review_status !== 'pending' && review_status !== 'later');

  if (laterCount !== initialLaterCount) {
    submitEnabled = reviewedData.some(({ review_status }) => review_status !== 'pending');
  }
  /* eslint-enable camelcase */

  const { primaryText, buttonHighlight, disableSearchFeatures } = authPagesConfig;

  const padding = small ? '1.5rem' : '2rem';
  const pageTitle = searchResultsData?.search?.title || 'Search Results';
  const searchCategory = searchResultsData?.search?.category;
  const subTitle = searchCategory ? `Blacklight - ${Case.capital(searchCategory)}` : 'Blacklight';

  const withBannerDetails = searchResultsData?.search?.details
    && searchResultsData.search.details.length >= 1;
  const withAttributes = searchResultsData?.search?.attributes
    && searchResultsData.search.attributes.length >= 1;
  const reviewable = searchResultsData?.data && (searchResultsData.data.find((dt) => dt.feature === 'review/update') !== undefined);
  const totalResults = searchResultsData?.data && searchResultsData.data.find((dt) => dt.label === 'Total infringements');

  const resultsPerPageOptions = [10, 15, 20, 25];

  const renderPageContent = () => {
    if (disableSearchFeatures) {
      return (
        <Box flex align="center" justify="center" background="white">
          <UnderConstruction
            imageUrl={authPagesConfig.constructionImage}
            buttonHighlight={authPagesConfig.buttonHighlight}
            header="Under Maintenance"
            message="System currently undergoing maintenance. Please check back later."
          />
        </Box>
      );
    }

    return (
      <Box flex background="#F1F2FC" pad={{ vertical: padding }}>
        {withAttributes && (
          <Box pad={{ horizontal: padding, bottom: padding }}>
            <SearchDetails attributes small={small} details={searchResultsData.search.attributes} textColor="#3C3C3C" weight={600} />
          </Box>
        )}
        <WidgetRenderer
          small={small}
          size={size}
          loading={loading}
          loaderColor={buttonHighlight}
          backgroundColor="white"
          textColor={primaryText}
          handleQuery={(parentKey, updateKey, value) => handleQuery(parentKey, updateKey, value)}
          handleSort={(sortValue, sortDirection) => handleSort(sortValue, sortDirection)}
          handleFilter={(key, value, predicate, seperateValues, reset) => (
            handleFilter(key, value, predicate, seperateValues, reset)
          )}
          filterSortMeta={searchResultsMeta}
          searchQuery={query}
          widgetData={searchResultsData?.data}
          padding={padding}
          authPagesConfig={authPagesConfig}
          selectedStatuses={reviewedData}
          initializeReviewedRows={initializeReviewedRows}
          updateRowReviewStatus={updateRowReviewStatus}
          resultsPerPageOptions={resultsPerPageOptions}
          resultsPerPage={query.paging.size}
          currentPage={query.paging.to}
          totalResults={totalResults?.value}
        />
        {searchResultsData?.data && reviewable && (
          <Box
            width="100%"
            direction={small ? 'column' : 'row'}
            gap={small ? '1rem' : '2rem'}
            justify="between"
            align="center"
            pad={{ horizontal: padding, vertical: '2rem' }}
          >
            <Box width={small ? '100%' : '65%'}>
              <Text size="1rem" weight={500} color={authPagesConfig.primaryText}>
                We will automatically send a DMCA notice to accounts marked as
                infringements after submission.
              </Text>
            </Box>
            <Box pad={{ right: small ? '0rem' : '4rem' }}>
              <AppButton
                overrideHover
                width="10rem"
                disabled={!submitEnabled || (reviewedData.length === 0)}
                onClick={() => sendReviewedResults(reviewedData)}
                level="dynamicLarge"
                color="white"
                bgColor={authPagesConfig.buttonHighlight}
                fontWeight={600}
                label="Submit Review"
                title={submitEnabled ? null : 'Please select a status option'}
              />
            </Box>
          </Box>
        )}
      </Box>
    );
  };

  return (
    <AuthNavWrapper
      small={small}
      mixpanel={mixpanel}
      location={location}
      authPagesConfig={authPagesConfig}
      customReports={customReports}
      cyclopsConfig={cyclopsConfig}
      bannerProps={{
        title: pageTitle,
        subTitle,
        textColor: 'white',
        bannerChildren: withBannerDetails ? (
          <SearchDetails small={small} details={searchResultsData.search.details} textColor="white" />
        ) : null,
        bannerChildrenPosition: 'bottom',
      }}
    >
      <Seo />
      {renderPageContent()}
    </AuthNavWrapper>
  );
};

function mapStateToProps(state) {
  return {
    searchResultsData: state.productDashboard.productSearchResultsData,
    searchResultsMeta: state.productDashboard.productSearchResultsMeta,
    loading: state.fetchLoader.dataLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchSearchResultsData: initiateProductDashboardSearchResultsRequest,
    clearSearchResultsData: clearProductDashboardSearchResultsRequest,
    updateSearchResultsReviewSelections: updateProductDashboardReviewSelectionsRequest,
    fetchSearchResultsMeta: initiateProductDashboardSearchResultsMetaRequest,
    clearSearchResultsMeta: clearProductDashboardSearchResultsMetaRequest,
  }, dispatch);
}

DashboardResultsPage.propTypes = {
  small: PropTypes.bool.isRequired,
  size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'mediumlarge', 'large']).isRequired,
  mixpanel: PropTypes.shape({
    track: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  fetchSearchResultsData: PropTypes.func.isRequired,
  clearSearchResultsData: PropTypes.func.isRequired,
  updateSearchResultsReviewSelections: PropTypes.func.isRequired,
  fetchSearchResultsMeta: PropTypes.func.isRequired,
  clearSearchResultsMeta: PropTypes.func.isRequired,
  searchResultsData: PropTypes.shape({
    query: PropTypes.shape({
      id: PropTypes.number.isRequired,
      paging: PropTypes.shape({
        to: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]).isRequired,
        size: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]).isRequired,
      }),
    }).isRequired,
    search: PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      category: PropTypes.string,
      details: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.arrayOf(PropTypes.string.isRequired),
        ]).isRequired,
        type: PropTypes.string.isRequired,
        unit: PropTypes.string,
      })),
      attributes: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.arrayOf(PropTypes.string.isRequired),
        ]).isRequired,
        type: PropTypes.string.isRequired,
        unit: PropTypes.string,
      })),
    }).isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
      type: PropTypes.string.isRequired,
      unit: PropTypes.string,
      feature: PropTypes.string,
    }).isRequired).isRequired,
  }),
  searchResultsMeta: PropTypes.shape({
    sort: PropTypes.shape({
      name: PropTypes.shape({
        options: PropTypes.arrayOf(PropTypes.shape({
          name: PropTypes.string.isRequired,
          formatted: PropTypes.string.isRequired,
        }).isRequired).isRequired,
      }).isRequired,
      direction: PropTypes.shape({
        options: PropTypes.arrayOf(PropTypes.shape({
          name: PropTypes.string.isRequired,
          formatted: PropTypes.string.isRequired,
        }).isRequired).isRequired,
      }).isRequired,
    }).isRequired,
    filters: PropTypes.shape({
      options: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        formatted: PropTypes.string.isRequired,
        validPredicates: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
      }).isRequired).isRequired,
    }).isRequired,
  }),
  authPagesConfig: PropTypes.shape({
    pageBg: PropTypes.string.isRequired,
    altComponentBg: PropTypes.string.isRequired,
    navBorder: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    hintText: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    focusHighlight: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    incrementText: PropTypes.string.isRequired,
    decrementText: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    iconHighlightColor: PropTypes.string.isRequired,
    constructionImage: PropTypes.string.isRequired,
    disableSearchFeatures: PropTypes.bool.isRequired,
  }),
  customReports: PropTypes.objectOf(PropTypes.any),
  cyclopsConfig: PropTypes.arrayOf(PropTypes.any),
};

export default connect(mapStateToProps, mapDispatchToProps)(withProductAuth(DashboardResultsPage));
