import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { format } from 'date-fns';

import { Box } from 'grommet';
import { ShareRounded } from 'grommet-icons';

import { Seo, ShareButton } from '@Components/Control';
import { withProductAuth } from '@Components/Layout';
import { AuthNavWrapper } from '@Components/Navigation';
import { UsersTablePartial } from '@Components/Partial/Product/CompanyUsers';
import { paths } from '@Components/configs';

import {
  fetchUsersRequest,
  clearUsersRequest,
  createNewUserRequest,
  deleteUserRequest,
  resendUserInviteRequest,
  fetchInviteTokenRequest,
  clearInviteTokenRequest,
} from '@Actions';


const CompanyUsersPage = ({
  small,
  mixpanel,
  location,
  loading,
  submitLoading,
  fetchCompanyUsers,
  clearCompanyUsers,
  createUser,
  deleteUser,
  resendInvite,
  currentUserEmail,
  fetchInviteToken,
  clearInviteToken,
  inviteToken,
  companyAccountUsers = null,
  authPagesConfig = null,
  customReports = null,
  cyclopsConfig = null,
}) => {
  React.useEffect(() => {
    fetchCompanyUsers();
    fetchInviteToken();

    return () => {
      clearCompanyUsers();
      clearInviteToken();
    };
  }, []);

  const headerMap = [
    { uid: 'firstName', display: 'First Name', size: small ? '100%' : '15%' },
    { uid: 'lastName', display: 'Last Name', size: small ? '100%' : '15%' },
    { uid: 'email', display: 'Email', size: small ? '100%' : '25%' },
    { uid: 'status', display: 'Status', size: small ? '100%' : '15%' },
    { uid: 'actions', display: 'Actions', size: small ? '100%' : '30%' },
  ];

  const handleDeleteUser = (userId) => {
    deleteUser(userId, paths.users, () => fetchCompanyUsers());
  };

  const handleResendInvite = (userId) => {
    resendInvite(userId, paths.users, () => fetchCompanyUsers());
  };

  const handleCreateUser = (body) => {
    createUser(body, paths.users, () => fetchCompanyUsers());
  };

  const tokenExpiration = inviteToken && new Date(inviteToken.expiresAt);
  const formattedExpiration = tokenExpiration && format(tokenExpiration, 'MM/dd/yyyy hh:mma');

  return (
    <AuthNavWrapper
      small={small}
      mixpanel={mixpanel}
      location={location}
      authPagesConfig={authPagesConfig}
      customReports={customReports}
      cyclopsConfig={cyclopsConfig}
      bannerProps={{
        title: 'Manage Authorized Users',
        subTitle: 'Account Settings',
        textColor: 'white',
        bannerChildren: inviteToken?.token && (
          <Box
            direction="row"
            justify="center"
            align="center"
            gap="1rem"
          >
            <ShareButton
              small={small}
              textColor={authPagesConfig.primaryText}
              buttonBg="white"
              buttonLabel="Generate invite link"
              renderKey="invite-users"
              copyMessage={`Expires on ${formattedExpiration}`}
              copyValue={`${window.location.origin}/invited?token=${inviteToken.token}`}
              icon={<ShareRounded color={authPagesConfig.primaryText} size="1rem" />}
            />
          </Box>
        ),
        bannerChildrenPosition: 'right',
      }}
    >
      <Seo />
      <Box
        flex
        direction="column"
        background="#F1F2FC"
        pad={{ vertical: '1.5rem' }}
        height="100%"
        gap="1rem"
      >
        <UsersTablePartial
          small={small}
          loading={loading || submitLoading}
          headerMap={headerMap}
          createUser={handleCreateUser}
          deleteUser={handleDeleteUser}
          resendInvite={handleResendInvite}
          usersData={companyAccountUsers}
          authPagesConfig={authPagesConfig}
          currentUserEmail={currentUserEmail}
        />
      </Box>
    </AuthNavWrapper>
  );
};

function mapStateToProps(state) {
  const {
    email, accountUsers, inviteToken,
  } = state.auth;

  return {
    loading: state.fetchLoader.dataLoading,
    submitLoading: state.fetchLoader.submissionLoading,
    companyAccountUsers: accountUsers,
    currentUserEmail: email,
    inviteToken,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchCompanyUsers: fetchUsersRequest,
    clearCompanyUsers: clearUsersRequest,
    createUser: createNewUserRequest,
    deleteUser: deleteUserRequest,
    resendInvite: resendUserInviteRequest,
    fetchInviteToken: fetchInviteTokenRequest,
    clearInviteToken: clearInviteTokenRequest,
  }, dispatch);
}

CompanyUsersPage.propTypes = {
  small: PropTypes.bool.isRequired,
  mixpanel: PropTypes.shape({
    track: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
  }).isRequired,
  authPagesConfig: PropTypes.shape({
    pageBg: PropTypes.string.isRequired,
    altComponentBg: PropTypes.string.isRequired,
    navBorder: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    hintText: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    focusHighlight: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    incrementText: PropTypes.string.isRequired,
    decrementText: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    iconHighlightColor: PropTypes.string.isRequired,
    constructionImage: PropTypes.string.isRequired,
  }),
  customReports: PropTypes.objectOf(PropTypes.any),
  cyclopsConfig: PropTypes.arrayOf(PropTypes.any),
  loading: PropTypes.bool.isRequired,
  submitLoading: PropTypes.bool.isRequired,
  fetchCompanyUsers: PropTypes.func.isRequired,
  clearCompanyUsers: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  resendInvite: PropTypes.func.isRequired,
  companyAccountUsers: PropTypes.arrayOf(PropTypes.shape({
    email: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired),
  currentUserEmail: PropTypes.string.isRequired,
  fetchInviteToken: PropTypes.func.isRequired,
  clearInviteToken: PropTypes.func.isRequired,
  inviteToken: PropTypes.shape({
    token: PropTypes.string.isRequired,
    expiresAt: PropTypes.string.isRequired,
  }),
};

export default connect(mapStateToProps, mapDispatchToProps)(withProductAuth(CompanyUsersPage));
