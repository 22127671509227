import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Box } from 'grommet';

import { Seo } from '@Components/Control';
import { withProductAuth } from '@Components/Layout';
import { AuthNavWrapper } from '@Components/Navigation';
import { CurrentDateContext } from '@Components/Context';
import { AnalyticsPartial, AnalyticsDemoContentPartial } from '@Components/Partial/Product/Cyclops';

import {
  initiateCyclopsAnalyticsRequest,
  clearCyclopsAnalyticsRequest,
  initiateCyclopsCompanyOrganizationsRequest,
  initiateCyclopsLivestreamsRequest,
  clearCyclopsLivestreamsRequest,
  initiateCyclopsPiracyStreamsRequest,
  clearCyclopsPiracyStreamsRequest,
} from '@Actions';

import { StyledCyclopsHeading } from '../../../Partial/Product/Cyclops/StyledCyclopsComponents';


const CyclopsAnalyticsPage = ({
  small,
  demoOnly,
  loading,
  mixpanel,
  location,
  fetchAnalyticsData,
  clearAnalyticsData,
  fetchCompanyOrganizations,
  fetchLivestreams,
  clearLivestreams,
  fetchPiracyStreams,
  clearPiracyStreams,
  analyticsData = null,
  companyOrganizations = null,
  livestreamsData = null,
  piracyStreamData = null,
  authPagesConfig = null,
  customReports = null,
  cyclopsConfig = null,
  companyDemoData = null,
}) => {
  const { today } = React.useContext(CurrentDateContext);
  /* eslint-disable camelcase */
  const [query, setQuery] = React.useState({
    since: null,
    content_owner_id: null,
    live_stream_id: null,
    piracy_stream_id: null,
  });
  const [selectedQueryKey, setSelectedQueryKey] = React.useState(null);

  React.useEffect(() => {
    if (!demoOnly) fetchAnalyticsData({ ...query });
    if (!demoOnly && (!companyOrganizations || companyOrganizations?.length === 0)) {
      fetchCompanyOrganizations();
    }

    return () => {
      if (!demoOnly) {
        clearAnalyticsData();

        if (livestreamsData) clearLivestreams();
        if (piracyStreamData) clearPiracyStreams();
      }
    };
  }, []);

  React.useEffect(() => {
    if (!demoOnly) {
      if (query.content_owner_id) {
        fetchLivestreams({ content_owner_id: query.content_owner_id }, true);
      }

      if (query.live_stream_id) {
        fetchPiracyStreams({ live_stream_id: query.live_stream_id }, true);
      }

      fetchAnalyticsData({ ...query });
    }
  }, [query]);

  const resetQuery = () => {
    setQuery({
      since: null,
      content_owner_id: null,
      live_stream_id: null,
      piracy_stream_id: null,
    });
  };

  const handleQuery = (key, value) => {
    setQuery((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const {
    darkModeColors: {
      primaryDarkBg, primaryText,
    },
  } = authPagesConfig;

  const renderPageContent = () => {
    if (demoOnly) {
      return (
        <AnalyticsDemoContentPartial
          small={small}
          authPagesConfig={authPagesConfig}
          companyDemoData={companyDemoData}
        />
      );
    }

    return (
      <AnalyticsPartial
        small={small}
        loading={loading}
        authPagesConfig={authPagesConfig}
        query={query}
        updateQuery={handleQuery}
        resetQuery={resetQuery}
        setSelectedQueryKey={setSelectedQueryKey}
        selectedQueryKey={selectedQueryKey}
        analyticsData={analyticsData?.data}
        companyOrganizations={companyOrganizations}
        livestreamsData={livestreamsData}
        piracyStreamData={piracyStreamData}
        today={today}
      />
    );
  };
  /* eslint-enable camelcase */

  const pageTitle = 'Livestream Analytics';

  return (
    <AuthNavWrapper
      hideBanner
      darkMode
      small={small}
      mixpanel={mixpanel}
      location={location}
      authPagesConfig={authPagesConfig}
      customReports={customReports}
      cyclopsConfig={cyclopsConfig}
    >
      <Seo />
      <Box
        flex
        background={primaryDarkBg}
        pad={small ? '2rem' : '3rem'}
        direction="column"
        gap="1rem"
      >
        <StyledCyclopsHeading level={2} color={primaryText}>
          {pageTitle}
        </StyledCyclopsHeading>
        {renderPageContent()}
      </Box>
    </AuthNavWrapper>
  );
};

function mapStateToProps(state) {
  return {
    analyticsData: state.cyclops.analyticsData,
    companyOrganizations: state.cyclops.companyOrganizations,
    livestreamsData: state.cyclops.organizationLivestreams,
    piracyStreamData: state.cyclops.piracyStreams,
    loading: state.fetchLoader.dataLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchAnalyticsData: initiateCyclopsAnalyticsRequest,
    clearAnalyticsData: clearCyclopsAnalyticsRequest,
    fetchCompanyOrganizations: initiateCyclopsCompanyOrganizationsRequest,
    fetchLivestreams: initiateCyclopsLivestreamsRequest,
    clearLivestreams: clearCyclopsLivestreamsRequest,
    fetchPiracyStreams: initiateCyclopsPiracyStreamsRequest,
    clearPiracyStreams: clearCyclopsPiracyStreamsRequest,
  }, dispatch);
}


CyclopsAnalyticsPage.propTypes = {
  small: PropTypes.bool.isRequired,
  demoOnly: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  mixpanel: PropTypes.shape({
    track: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
  }).isRequired,
  authPagesConfig: PropTypes.shape({
    pageBg: PropTypes.string.isRequired,
    altComponentBg: PropTypes.string.isRequired,
    navBorder: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    hintText: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    focusHighlight: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    incrementText: PropTypes.string.isRequired,
    decrementText: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    iconHighlightColor: PropTypes.string.isRequired,
    constructionImage: PropTypes.string.isRequired,
    darkModeColors: PropTypes.shape({
      containerBg: PropTypes.string.isRequired,
      primaryDarkBg: PropTypes.string.isRequired,
      primaryText: PropTypes.string.isRequired,
      secondaryText: PropTypes.string.isRequired,
      incrementText: PropTypes.string.isRequired,
      decrementText: PropTypes.string.isRequired,
    }).isRequired,
  }),
  customReports: PropTypes.objectOf(PropTypes.any),
  cyclopsConfig: PropTypes.arrayOf(PropTypes.any),
  companyDemoData: PropTypes.objectOf(PropTypes.any),
  analyticsData: PropTypes.shape({
    data: PropTypes.shape({
      authedViewership: PropTypes.number,
      averageTimeToKill: PropTypes.number,
      pirateStreamsN: PropTypes.number,
      pirateStreamsTraffic: PropTypes.number,
      streamsShutdown: PropTypes.number,
      uniqueDevicesAboveThresholdN: PropTypes.number,
      pirateGeoStats: PropTypes.objectOf(PropTypes.number),
    }),
  }),
  companyOrganizations: PropTypes.arrayOf(PropTypes.shape({
    Id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })),
  livestreamsData: PropTypes.arrayOf(PropTypes.shape({
    Id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })),
  piracyStreamData: PropTypes.arrayOf(PropTypes.shape({
    Id: PropTypes.string.isRequired,
    piracyUrl: PropTypes.string.isRequired,
    pirateId: PropTypes.string.isRequired,
  })),
  fetchAnalyticsData: PropTypes.func.isRequired,
  clearAnalyticsData: PropTypes.func.isRequired,
  fetchCompanyOrganizations: PropTypes.func.isRequired,
  fetchLivestreams: PropTypes.func.isRequired,
  clearLivestreams: PropTypes.func.isRequired,
  fetchPiracyStreams: PropTypes.func.isRequired,
  clearPiracyStreams: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps, mapDispatchToProps,
)(withProductAuth(CyclopsAnalyticsPage));
