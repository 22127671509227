import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import queryString from 'query-string';

import { Box } from 'grommet';

import {
  refreshReportAccessDataRequest,
  clearReportAccessDataRequest,
} from '@Actions';
import {
  secondsSinceEpoch, baseServerUrl, selectEnvironment,
} from '@Helpers/util';
import { DomoEmbedPartial } from '@Components/Partial/Product/Overview';


const NoAuthReportPage = ({
  small,
  loading,
  location,
  mixpanel,
  authPagesConfig = null,
  refreshAccessData,
  clearAccessData,
  accessToken = null,
  accessTokenExpiration = null,
}) => {
  const [embedExpired, setEmbedExpired] = React.useState(false);
  const [embedLinkInvalid, setEmbedLinkInvalid] = React.useState(false);
  const [embedToken, setEmbedToken] = React.useState(null);
  const [embedData, setEmbedData] = React.useState({
    embedId: null,
    embedTitle: null,
    applyCompanyFilter: null,
    companyId: null,
    pageTitle: null,
  });

  const useSearch = location.search.substring(1);
  const decodedParams = atob(useSearch);

  React.useEffect(() => {
    if (decodedParams) {
      const params = queryString.parse(decodedParams);
      const expiresAt = parseInt(params.expiresAt, 10);

      if (expiresAt < secondsSinceEpoch()) {
        setEmbedExpired(true);
      } else {
        setEmbedData({
          embedId: params.embedId,
          embedTitle: params.embedTitle,
          applyCompanyFilter: params.applyCompanyFilter && params.applyCompanyFilter === 'true',
          companyId: parseInt(params.companyId, 10),
          pageTitle: 'Shared Report',
        });

        mixpanel.track('shared_report_view', {
          path: location.pathname,
          embedTitle: params.embedTitle,
          embedId: params.embedId,
          environment: selectEnvironment(),
        });
      }
    } else {
      setEmbedLinkInvalid(true);
    }
  }, [decodedParams]);

  const fetchServerEmbedToken = async (useToken) => {
    const options = { method: 'GET' };
    let embedParams = `token=${useToken}&embedId=${embedData.embedId}`;

    if (embedData.applyCompanyFilter) {
      embedParams += `&companyId=${embedData.companyId}`;
    }

    const response = await fetch(`${baseServerUrl()}api/embedToken?${embedParams}`, options);
    const responseContent = await response.text();
    setEmbedToken(responseContent);
  };

  React.useEffect(() => {
    if (!accessToken || accessTokenExpiration - 60 > secondsSinceEpoch()) {
      refreshAccessData();
    }

    return () => {
      clearAccessData();
    };
  }, []);

  React.useEffect(() => {
    if (accessToken && embedData.embedId && !embedLinkInvalid && !embedExpired) {
      fetchServerEmbedToken(accessToken);
    }
  }, [accessToken, embedData, embedLinkInvalid, embedExpired]);

  const { primaryText, buttonHighlight } = authPagesConfig;

  return (
    <Box flex background="#F1F2FC" pad={{ top: '7.2rem', bottom: '1rem', horizontal: small ? '0.5rem' : '5vw' }} height={{ min: '100vh' }}>
      <DomoEmbedPartial
        noAuth
        loading={loading}
        textColor={primaryText}
        highlightColor={buttonHighlight}
        embedId={embedData.embedId}
        embedToken={embedToken}
        embedExpired={embedExpired}
        embedLinkInvalid={embedLinkInvalid}
      />
    </Box>
  );
};

function mapStateToProps(state) {
  return {
    accessToken: state.productEmbed.reportAccessToken,
    accessTokenExpiration: state.productEmbed.reportAccessTokenExpiration,
    loading: state.fetchLoader.dataLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    refreshAccessData: refreshReportAccessDataRequest,
    clearAccessData: clearReportAccessDataRequest,
  }, dispatch);
}

NoAuthReportPage.propTypes = {
  small: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
  }).isRequired,
  mixpanel: PropTypes.shape({
    track: PropTypes.func.isRequired,
  }).isRequired,
  authPagesConfig: PropTypes.shape({
    pageBg: PropTypes.string.isRequired,
    altComponentBg: PropTypes.string.isRequired,
    navBorder: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    hintText: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    focusHighlight: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    incrementText: PropTypes.string.isRequired,
    decrementText: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    iconHighlightColor: PropTypes.string.isRequired,
    constructionImage: PropTypes.string.isRequired,
  }),
  refreshAccessData: PropTypes.func.isRequired,
  clearAccessData: PropTypes.func.isRequired,
  accessToken: PropTypes.string,
  accessTokenExpiration: PropTypes.number,
};

export default connect(mapStateToProps, mapDispatchToProps)(NoAuthReportPage);
