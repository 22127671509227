import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, useSearchParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Form } from 'grommet';

import SubmissionLayout, { StyledSubmissionInput, StyledSubmissionField } from '@Components/Layout/Submission';
import { AppButton } from '@Components/Control';

import { updatePasswordRequest } from '@Actions';

import { paths } from '@Components/configs';

const RecoverAccountContent = ({
  small,
  isSignup = false,
  updatePassword,
  resetPasswordToken,
  config: {
    bgColor,
    bgImage,
    textColor,
    highlight,
  },
}) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    const { password } = e.value;

    updatePassword(password, resetPasswordToken, paths.login);
  };

  const pageTitle = isSignup ? 'Set up your account' : 'Recover your account';
  const placeholder = isSignup ? 'Create a password' : 'New password';
  const submitMessage = isSignup ? 'Create Account' : 'Update Password';

  return (
    <SubmissionLayout
      withBackButton
      seoConfig={null}
      title={pageTitle}
      bgColor={bgColor}
      bgImage={bgImage}
      textColor={textColor}
      small={small}
    >
      <Form onSubmit={(e) => handleSubmit(e)}>
        <StyledSubmissionField required name="password" htmlFor="password" type="password">
          <StyledSubmissionInput
            plain
            name="password"
            type="password"
            placeholder={placeholder}
            highlight={highlight}
          />
        </StyledSubmissionField>
        <AppButton
          overrideHover
          type="submit"
          level="authSubmit"
          bgColor={highlight}
          color="white"
          label={submitMessage}
          fontWeight={600}
          alignSelf="center"
        />
      </Form>
    </SubmissionLayout>
  );
};

RecoverAccountContent.propTypes = {
  small: PropTypes.bool.isRequired,
  resetPasswordToken: PropTypes.string.isRequired,
  updatePassword: PropTypes.func.isRequired,
  config: PropTypes.shape({
    bgColor: PropTypes.string.isRequired,
    bgImage: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,
    highlight: PropTypes.string.isRequired,
  }).isRequired,
  isSignup: PropTypes.bool,
};

const RecoverAccountPage = ({
  small, updatePassword, config,
}) => {
  const [searchParams] = useSearchParams();
  const recoveryToken = searchParams.get('reset_password_token');
  const signup = searchParams.get('account_setup');
  const isSignup = signup && (parseInt(signup, 10) === 1);

  if (recoveryToken && !isSignup) {
    return (
      <RecoverAccountContent
        small={small}
        resetPasswordToken={recoveryToken}
        updatePassword={updatePassword}
        config={config}
      />
    );
  }

  if (recoveryToken && isSignup) {
    return (
      <RecoverAccountContent
        isSignup
        small={small}
        resetPasswordToken={recoveryToken}
        updatePassword={updatePassword}
        config={config}
      />
    );
  }

  return <Navigate to={paths.root} />;
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ updatePassword: updatePasswordRequest }, dispatch);
}

RecoverAccountPage.propTypes = {
  small: PropTypes.bool.isRequired,
  updatePassword: PropTypes.func.isRequired,
  config: PropTypes.shape({
    bgColor: PropTypes.string.isRequired,
    bgImage: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,
    highlight: PropTypes.string.isRequired,
  }).isRequired,
};

export default connect(null, mapDispatchToProps)(RecoverAccountPage);
