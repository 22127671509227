import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import uniqid from 'uniqid';

import {
  Box, Text, Button,
} from 'grommet';

import {
  Seo, FullHeightBox, AppAnchor,
} from '@Components/Control';
import { withProductAuth } from '@Components/Layout';
import { AuthNavWrapper, AuthBanner } from '@Components/Navigation';
import { sendPasswordResetInstructionsRequest, authUploadCompanyLogoRequest } from '@Actions';
import { paths } from '@Components/configs';

import StyledAccountInfoContainer from './StyledAccountPage';
import { ProfileLogo } from '../../Navigation/HeaderComponent';


const PageWrapper = ({
  small,
  mixpanel,
  location,
  authPagesConfig,
  unconfiguredCompany = false,
  customReports = null,
  cyclopsConfig = null,
  children,
}) => {
  if (unconfiguredCompany) {
    return (
      <FullHeightBox background={authPagesConfig.pageBg} pad={{ top: small ? '5.2rem' : '3rem' }}>
        <AuthBanner
          navCollapsed
          adjustLeftPad
          small={small}
          image={authPagesConfig.bannerBg}
          textColor="white"
          title="Account information"
          subTitle="Plan and profile settings"
          bannerChildren={null}
        />
        {children}
      </FullHeightBox>
    );
  }

  return (
    <AuthNavWrapper
      small={small}
      mixpanel={mixpanel}
      location={location}
      authPagesConfig={authPagesConfig}
      customReports={customReports}
      cyclopsConfig={cyclopsConfig}
      bannerProps={{
        title: 'Account information',
        subTitle: 'Plan and profile settings',
        textColor: 'white',
      }}
    >
      {children}
    </AuthNavWrapper>
  );
};

PageWrapper.propTypes = {
  small: PropTypes.bool.isRequired,
  mixpanel: PropTypes.shape({
    track: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
  }).isRequired,
  authPagesConfig: PropTypes.shape({
    pageBg: PropTypes.string.isRequired,
    bannerBg: PropTypes.string.isRequired,
    altComponentBg: PropTypes.string.isRequired,
    navBorder: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    hintText: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    focusHighlight: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    incrementText: PropTypes.string.isRequired,
    decrementText: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    iconHighlightColor: PropTypes.string.isRequired,
    constructionImage: PropTypes.string.isRequired,
  }).isRequired,
  customReports: PropTypes.objectOf(PropTypes.any),
  cyclopsConfig: PropTypes.arrayOf(PropTypes.any),
  unconfiguredCompany: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

const SectionContainer = ({
  small, sectionTitle, textColor, children, ...rest
}) => {
  const borderProps = { size: '1px', style: 'solid', side: 'bottom' };

  return (
    <Box direction="column" gap="1.5rem" background="white" round="10px" pad="1.5rem" {...rest}>
      <Box width={small ? '55%' : '22%'} border={{ color: '#DDE2E4', ...borderProps }} style={{ minWidth: '15rem' }}>
        <Box
          flex={false}
          border={{ color: '#252C32', ...borderProps }}
          justify="end"
          pad={{ bottom: '0.5rem' }}
          width={small ? '40%' : '30%'}
        >
          <Text color={textColor} weight={600} size="1.25rem">
            {sectionTitle}
          </Text>
        </Box>
      </Box>
      <Box direction="column" gap="2rem">
        {children}
      </Box>
    </Box>
  );
};

SectionContainer.propTypes = {
  small: PropTypes.bool.isRequired,
  sectionTitle: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const AccountPage = ({
  small,
  mixpanel,
  location,
  companyEmail,
  companyLogo,
  sendResetInstructions,
  uploadCompanyLogo,
  authPagesConfig = null,
  unconfiguredCompany = false,
  customReports = null,
  cyclopsConfig = null,
}) => {
  const logoUploadRef = React.useRef(null);
  const {
    primaryText, highlightText, buttonHighlight, accountPlaceholder,
  } = authPagesConfig;

  const replaceWithDots = (str) => (str.replace(/[^ ]/g, '•'));
  const customerMessage = unconfiguredCompany
    ? 'Account features have not yet been activated. To activate, please contact Redflag at'
    : 'If you would like to make changes to your plan, please contact Redflag at';

  return (
    <PageWrapper
      small={small}
      mixpanel={mixpanel}
      location={location}
      authPagesConfig={authPagesConfig}
      unconfiguredCompany={unconfiguredCompany}
      customReports={customReports}
      cyclopsConfig={cyclopsConfig}
    >
      <Seo />
      <Box flex direction="column" background="#F4F4F4" pad={small ? '2rem' : '3rem'}>
        <input
          hidden
          key={uniqid()}
          type="file"
          accept="image/*"
          onChange={(e) => uploadCompanyLogo(e.currentTarget.files[0])}
          ref={logoUploadRef}
        />
        <SectionContainer small={small} sectionTitle="Plan" textColor={primaryText} margin={{ bottom: '2rem' }}>
          <Text color={primaryText} weight={400} size="1rem">
            {customerMessage}
            {' '}
            <AppAnchor href="mailto:contact@redflagai.co" target="_blank" color={highlightText}>
              contact@redflagai.co
            </AppAnchor>
          </Text>
        </SectionContainer>
        <SectionContainer small={small} sectionTitle="Profile" textColor={primaryText}>
          <Box direction="column" gap="0.85rem">
            <ProfileLogo
              small={small}
              logoUrl={companyLogo}
              placeholder={accountPlaceholder}
              outlineColor={buttonHighlight}
              customSize={small ? '7rem' : '6rem'}
            />
            <Button plain onClick={() => logoUploadRef.current.click()} style={{ maxWidth: 'fit-content' }}>
              <Text weight={500} size="0.9rem" color={highlightText}>Upload photo</Text>
            </Button>
          </Box>
          <Box direction="column" gap="1.35rem">
            <Text size="1rem">This is your registered email, you may reset your password by clicking the link below and following the instructions you&apos;ll recieve by email</Text>
            <StyledAccountInfoContainer focusHighlight={highlightText} width={{ width: small ? '100%' : '40%' }}>
              <Text size="1rem" color={primaryText} weight={500}>
                {companyEmail}
              </Text>
            </StyledAccountInfoContainer>
            <Box>
              <StyledAccountInfoContainer focusHighlight={highlightText} width={{ width: small ? '100%' : '40%' }}>
                <Text size="1rem" color={primaryText} weight={500}>
                  {replaceWithDots(uniqid())}
                </Text>
              </StyledAccountInfoContainer>
              <Box pad={{ top: '0.5rem' }}>
                <Button plain onClick={() => sendResetInstructions(companyEmail, paths.account)} style={{ maxWidth: 'fit-content' }}>
                  <Text weight={500} size="0.9rem" color={highlightText}>Reset password</Text>
                </Button>
              </Box>
            </Box>
          </Box>
        </SectionContainer>
      </Box>
    </PageWrapper>
  );
};

function mapStateToProps(state) {
  const { email: companyEmail, companyLogo } = state.auth;

  return { companyEmail, companyLogo };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    sendResetInstructions: sendPasswordResetInstructionsRequest,
    uploadCompanyLogo: authUploadCompanyLogoRequest,
  }, dispatch);
}

AccountPage.propTypes = {
  small: PropTypes.bool.isRequired,
  mixpanel: PropTypes.shape({
    track: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
  }).isRequired,
  authPagesConfig: PropTypes.shape({
    pageBg: PropTypes.string.isRequired,
    bannerBg: PropTypes.string.isRequired,
    altComponentBg: PropTypes.string.isRequired,
    navBorder: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    hintText: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    focusHighlight: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    incrementText: PropTypes.string.isRequired,
    decrementText: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    iconHighlightColor: PropTypes.string.isRequired,
    accountPlaceholder: PropTypes.string.isRequired,
  }),
  customReports: PropTypes.objectOf(PropTypes.any),
  cyclopsConfig: PropTypes.arrayOf(PropTypes.any),
  unconfiguredCompany: PropTypes.bool,
  companyEmail: PropTypes.string.isRequired,
  companyLogo: PropTypes.string,
  sendResetInstructions: PropTypes.func.isRequired,
  uploadCompanyLogo: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withProductAuth(AccountPage));
