import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { createBucketClient } from '@cosmicjs/sdk';
/* eslint-disable import/no-unresolved */
import { Analytics } from '@vercel/analytics/react';
/* eslint-enable import/no-unresolved */

import { Grommet } from 'grommet';
import { ResponsiveContext } from 'grommet/contexts';

import { ScreenSizeContext, CurrentDateContext } from '@Components/Context';
import { logCloudwatchError } from '@Helpers/util';
import AppRoutes from './AppRoutes';

import theme from './theme';

const cosmic = createBucketClient({
  bucketSlug: 'redflag-ai-production',
  readKey: process.env.COSMIC_API_READ_KEY,
});

const fetchConfig = async (param, isMultiple = false) => {
  let config = null;
  const data = await cosmic.objects.find({ type: param }).props(['slug,title,metadata']);

  if (data && !isMultiple) {
    config = data.objects[0].metadata.config;
  } else {
    config = data.objects;
  }

  return config;
};

const Root = () => {
  const [today, setToday] = React.useState(null);
  const [authPagesConfig, setAuthPagesConfig] = React.useState(null);
  const [customReportsConfig, setCustomReportsConfig] = React.useState(null);
  const [cyclopsConfig, setCyclopsConfig] = React.useState(null);

  React.useEffect(() => {
    setToday(new Date());
    fetchConfig('auth-pages-v3').then((result) => setAuthPagesConfig(result));
    fetchConfig('embed-settings', true).then((result) => setCustomReportsConfig(result));
    fetchConfig('cyclops-api', true).then((result) => setCyclopsConfig(result));

    // Global error handler, logs to Cloudwatch
    const handleGlobalError = (event) => {
      logCloudwatchError('ERROR', event.message, event.error.stack);
    };

    window.addEventListener('error', handleGlobalError);

    return () => {
      window.removeEventListener('error', handleGlobalError);
    };
  }, []);

  if (!authPagesConfig || !customReportsConfig) return null;

  return (
    <Grommet theme={theme}>
      <ResponsiveContext.Consumer>
        {(size) => (
          <ScreenSizeContext.Provider value={{ size }}>
            <CurrentDateContext.Provider value={{ today }}>
              <Router>
                <AppRoutes
                  size={size}
                  authPagesConfig={authPagesConfig}
                  customReportsConfig={customReportsConfig}
                  cyclopsConfig={cyclopsConfig}
                />
              </Router>
            </CurrentDateContext.Provider>
          </ScreenSizeContext.Provider>
        )}
      </ResponsiveContext.Consumer>
      <Analytics />
    </Grommet>
  );
};

export default Root;
