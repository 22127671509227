import React from 'react';
import PropTypes from 'prop-types';
import Case from 'case';

import {
  Box, Text, Button,
} from 'grommet';

import { Seo, Spinning } from '@Components/Control';
import { withProductAuth } from '@Components/Layout';
import { AuthNavWrapper } from '@Components/Navigation';
import { ProductFormSingleTextInput } from '@Components/Partial/Product/NewSearch';
import { paths } from '@Components/configs';
import { localAsset } from '@Helpers/asset';

import { StyledSortButton } from './StyledPiracyOverviewPage';
import { TableWrapper } from '../../../Partial/Product/SharedComponents';
import { StyledSVG } from '../../../Partial/Product/StyledSharedComponents';


const TableControls = ({
  focusHighlight,
  primaryText,
  selectedSortOption,
  handleSort,
  iconPath,
}) => (
  <Box direction="row" gap="0.5rem">
    <StyledSortButton
      plain
      overrideHover
      key="sort"
      textSize="1rem"
      color={primaryText}
      label={selectedSortOption.name}
      highlight={focusHighlight}
      onClick={() => handleSort(selectedSortOption.direction === 'desc' ? 'asc' : 'desc')}
      icon={(
        <StyledSVG
          src={localAsset(iconPath)}
          width="1.1rem"
          height="1.1rem"
          title={selectedSortOption.name}
        />
      )}
    />
  </Box>
);

TableControls.propTypes = {
  focusHighlight: PropTypes.string.isRequired,
  primaryText: PropTypes.string.isRequired,
  iconPath: PropTypes.string.isRequired,
  selectedSortOption: PropTypes.shape({
    name: PropTypes.string.isRequired,
    direction: PropTypes.oneOf(['asc', 'desc']).isRequired,
  }).isRequired,
  handleSort: PropTypes.func.isRequired,
};

const ReportsTable = ({
  small,
  primaryText,
  buttonHighlight,
  focusHighlight,
  sortOrder,
  handleSort,
  sectionTitle,
  reportsData = null,
}) => {
  const renderReports = () => {
    if (reportsData && reportsData.length >= 1) {
      return (
        <Box direction="column">
          {reportsData.map(({ embedUid, embedTitle }, i) => (
            <Button plain key={embedUid} href={paths.productReport.replace(':id', embedUid)}>
              <Box
                wrap={false}
                fill="horizontal"
                direction={small ? 'column' : 'row'}
                border={(i !== reportsData.length - 1) && {
                  color: '#DDE2E4', size: '1px', style: 'solid', side: 'bottom',
                }}
                pad={{ vertical: '1rem' }}
                align={small ? 'start' : 'center'}
                gap={small ? '0.75rem' : '0'}
              >
                <Text size="1.15rem" weight={600} color={primaryText}>{embedTitle}</Text>
              </Box>
            </Button>
          ))}
        </Box>
      );
    }

    return (
      <Box flex justify="center" align="center">
        <Spinning size="large" color={buttonHighlight} />
      </Box>
    );
  };

  return (
    <Box direction="column">
      <TableWrapper
        small={small}
        header={sectionTitle}
        textColor={primaryText}
        boxPad={{ horizontal: '1.5rem', top: '1.5rem' }}
        tableControls={(
          <TableControls
            small={small}
            focusHighlight={focusHighlight}
            primaryText={primaryText}
            displayInputValue={null}
            selectedSortOption={sortOrder}
            handleSort={(sortDirection) => handleSort(sortDirection)}
            iconPath={`images/sort-input-icon-${sortOrder.direction}.svg`}
          />
        )}
        round="10px"
        border={{
          side: 'all', color: '#AAAAAA', size: '1px', style: 'solid',
        }}
      >
        {renderReports()}
      </TableWrapper>
    </Box>
  );
};

ReportsTable.propTypes = {
  small: PropTypes.bool.isRequired,
  sectionTitle: PropTypes.string.isRequired,
  primaryText: PropTypes.string.isRequired,
  buttonHighlight: PropTypes.string.isRequired,
  focusHighlight: PropTypes.string.isRequired,
  sortOrder: PropTypes.shape({
    name: PropTypes.string.isRequired,
    direction: PropTypes.oneOf(['asc', 'desc']).isRequired,
  }).isRequired,
  handleSort: PropTypes.func.isRequired,
  reportsData: PropTypes.arrayOf(PropTypes.shape({
    embedUid: PropTypes.string.isRequired,
    embedTitle: PropTypes.string.isRequired,
  })),
};

const ReportsListPage = ({
  small,
  mixpanel,
  location,
  authPagesConfig = null,
  customReports = null,
  categoryReports = null,
  cyclopsConfig = null,
}) => {
  const [customDisplayReports, setCustomDisplayReports] = React.useState(null);
  const [categoryDisplayReports, setCategoryDisplayReports] = React.useState(null);
  const [allReports, setAllReports] = React.useState([]);
  const [sortOrder, setSortOrder] = React.useState({ name: 'Sort A-Z', direction: 'desc' });
  const [categorySortOrder, setCategorySortOrder] = React.useState({});
  const [searchValue, setSearchValue] = React.useState('');
  const [searchResults, setSearchResults] = React.useState([]);

  const {
    primaryText, buttonHighlight, focusHighlight,
  } = authPagesConfig;

  React.useEffect(() => {
    if (!customDisplayReports && customReports?.metadata?.config?.customReports
      && customReports.metadata.config.customReports.length >= 1) {
      const sortedReports = customReports.metadata.config.customReports.sort((a, b) => (
        a.embedTitle.toLowerCase().localeCompare(b.embedTitle.toLowerCase())
      ));

      setCustomDisplayReports(sortedReports);
    }
  }, [customReports]);

  React.useEffect(() => {
    if (!categoryDisplayReports && categoryReports) {
      const formattedReports = Object.entries(categoryReports).map(([key, value]) => {
        const values = Object.entries(value).map(([k, v]) => {
          const name = v.customReportName || Case.title(k);
          return { ...v, embedTitle: name };
        });

        const sortedValues = values.sort((a, b) => (
          a.embedTitle.toLowerCase().localeCompare(b.embedTitle.toLowerCase())
        ));

        return { [key]: sortedValues };
      });

      const reportsOrder = ['infringementsReports', 'takedownsReports', 'complianceReports', 'insightsReports'];
      const sortedReports = formattedReports.sort((a, b) => (
        reportsOrder.indexOf(Object.keys(a)[0]) - reportsOrder.indexOf(Object.keys(b)[0])
      ));

      setCategoryDisplayReports(sortedReports);
      setCategorySortOrder(Object.fromEntries(Object.keys(categoryReports).map(
        (key) => [key, { name: 'Sort A-Z', direction: 'desc' }],
      )));
    }
  }, [categoryReports]);

  React.useEffect(() => {
    const categoryDisplayValues = categoryDisplayReports?.length > 0
      && categoryDisplayReports.map((category) => (Object.values(category)[0])).flat();

    if (customDisplayReports?.length > 0 && categoryDisplayValues) {
      setAllReports([...customDisplayReports, ...categoryDisplayValues]);
    } else if (customDisplayReports?.length > 0) {
      setAllReports([...customDisplayReports]);
    } else if (categoryDisplayValues) {
      setAllReports([...categoryDisplayValues]);
    }
  }, [customDisplayReports, categoryDisplayReports]);

  React.useEffect(() => {
    if (searchValue) {
      const filteredAllReports = allReports.filter((report) => (
        report.embedTitle.toLowerCase().includes(searchValue.toLowerCase())
      ));
      setSearchResults(filteredAllReports);
    } else {
      setSearchResults([]);
    }
  }, [searchValue]);

  const handleSort = (sortDirection) => {
    const prevReports = [...customDisplayReports];

    if (sortDirection === 'desc') {
      prevReports.sort((a, b) => (
        a.embedTitle.toLowerCase().localeCompare(b.embedTitle.toLowerCase())
      ));
      setSortOrder({ name: 'Sort A-Z', direction: 'desc' });
    } else {
      prevReports.sort((a, b) => (
        b.embedTitle.toLowerCase().localeCompare(a.embedTitle.toLowerCase())
      ));
      setSortOrder({ name: 'Sort Z-A', direction: 'asc' });
    }

    setCustomDisplayReports(prevReports);
  };

  const handleCategorySort = (categoryKey, sortDirection) => {
    const prevReports = [...categoryDisplayReports];

    const categoryIndex = prevReports.findIndex((category) => (
      Object.keys(category)[0] === categoryKey
    ));
    const categoryValue = Object.values(prevReports[categoryIndex])[0];

    if (sortDirection === 'desc') {
      categoryValue.sort((a, b) => (
        a.embedTitle.toLowerCase().localeCompare(b.embedTitle.toLowerCase())
      ));
    } else {
      categoryValue.sort((a, b) => (
        b.embedTitle.toLowerCase().localeCompare(a.embedTitle.toLowerCase())
      ));
    }

    prevReports[categoryIndex] = { [categoryKey]: categoryValue };
    setCategoryDisplayReports(prevReports);
    setCategorySortOrder((prev) => ({
      ...prev,
      [categoryKey]: {
        name: sortDirection === 'asc' ? 'Sort Z-A' : 'Sort A-Z',
        direction: sortDirection === 'asc' ? 'asc' : 'desc',
      },
    }));
  };

  return (
    <AuthNavWrapper
      small={small}
      mixpanel={mixpanel}
      location={location}
      authPagesConfig={authPagesConfig}
      customReports={customReports}
      cyclopsConfig={cyclopsConfig}
      bannerProps={{
        title: 'All reports',
        subTitle: 'Content protection hub',
        textColor: 'white',
      }}
    >
      <Seo />
      <Box
        flex
        direction="column"
        background="#F1F2FC"
        pad="2rem"
        height="100%"
        gap="1.5rem"
      >
        {allReports.length > 0 && (
          <Box direction="column" gap="1rem">
            <Box width={small ? '60%' : '35%'}>
              <ProductFormSingleTextInput
                focusHighlight={focusHighlight}
                value={searchValue}
                handleFormValues={(val) => setSearchValue(val)}
                placeholder="Search all reports"
                small={small}
                onClear={() => {
                  setSearchValue('');
                  setSearchResults([]);
                }}
                clearActive={searchValue !== ''}
              />
            </Box>
            {searchResults.length > 0 && (
              <Box
                direction="column"
                width={small ? '80%' : '60%'}
                background="#FFFFFF"
                round="10px"
                pad="1rem"
                border={{
                  side: 'all', color: '#AAAAAA', size: '1px', style: 'solid',
                }}
              >
                <Box pad={{ vertical: '0.5rem' }}>
                  <Text size="1rem" weight={400} color={primaryText}>Results:</Text>
                </Box>
                {searchResults.map(({ embedTitle, embedUid }, i) => (
                  <Button plain key={embedUid} href={paths.productReport.replace(':id', embedUid)}>
                    <Box
                      wrap={false}
                      fill="horizontal"
                      direction={small ? 'column' : 'row'}
                      border={(i !== searchResults.length - 1) && {
                        color: '#DDE2E4', size: '1px', style: 'solid', side: 'bottom',
                      }}
                      pad={{ vertical: '1rem', left: '0.5rem' }}
                      align={small ? 'start' : 'center'}
                      gap={small ? '0.75rem' : '0'}
                    >
                      <Box direction="row" gap="0.5rem">
                        <Text size="1rem" weight={500} color={buttonHighlight}>⟶</Text>
                        <Text size="1.15rem" weight={600} color={primaryText}>{embedTitle}</Text>
                      </Box>
                    </Box>
                  </Button>
                ))}
              </Box>
            )}
          </Box>
        )}
        {categoryDisplayReports?.length >= 1 && (
          <Box direction="column" gap="1.5rem">
            {categoryDisplayReports.map((category) => {
              const key = Object.keys(category)[0];
              const value = Object.values(category)[0];

              return (
                <ReportsTable
                  key={key}
                  small={small}
                  primaryText={primaryText}
                  buttonHighlight={buttonHighlight}
                  focusHighlight={focusHighlight}
                  sortOrder={categorySortOrder[key]}
                  handleSort={(sortDirection) => handleCategorySort(key, sortDirection)}
                  sectionTitle={Case.title(key.replace('Reports', ''))}
                  reportsData={value}
                />
              );
            })}
          </Box>
        )}
        {customDisplayReports?.length > 0 && (
          <ReportsTable
            small={small}
            primaryText={primaryText}
            buttonHighlight={buttonHighlight}
            focusHighlight={focusHighlight}
            sortOrder={sortOrder}
            handleSort={handleSort}
            sectionTitle="Custom"
            reportsData={customDisplayReports}
          />
        )}
      </Box>
    </AuthNavWrapper>
  );
};

ReportsListPage.propTypes = {
  small: PropTypes.bool.isRequired,
  mixpanel: PropTypes.shape({
    track: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
  }).isRequired,
  authPagesConfig: PropTypes.shape({
    pageBg: PropTypes.string.isRequired,
    altComponentBg: PropTypes.string.isRequired,
    navBorder: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    hintText: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    focusHighlight: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    incrementText: PropTypes.string.isRequired,
    decrementText: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    iconHighlightColor: PropTypes.string.isRequired,
    constructionImage: PropTypes.string.isRequired,
  }),
  customReports: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      config: PropTypes.shape({
        companyId: PropTypes.number.isRequired,
        customReports: PropTypes.arrayOf(PropTypes.shape({
          embedId: PropTypes.string.isRequired,
          embedUid: PropTypes.string.isRequired,
          embedTitle: PropTypes.string.isRequired,
          applyCompanyFilter: PropTypes.bool,
        })).isRequired,
      }).isRequired,
    }).isRequired,
  }),
  categoryReports: PropTypes.objectOf(PropTypes.objectOf(PropTypes.shape({
    embedId: PropTypes.string.isRequired,
    embedUid: PropTypes.string.isRequired,
    customReportName: PropTypes.string,
    applyCompanyFilter: PropTypes.bool,
  }))),
  cyclopsConfig: PropTypes.arrayOf(PropTypes.any),
};

export default withProductAuth(ReportsListPage);
