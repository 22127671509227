import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useParams } from 'react-router-dom';
import Case from 'case';

import { Box } from 'grommet';

import { Seo } from '@Components/Control';
import { withProductAuth } from '@Components/Layout';
import { AuthNavWrapper } from '@Components/Navigation';
import {
  refreshReportAccessDataRequest,
  clearReportAccessDataRequest,
} from '@Actions';
import { secondsSinceEpoch, baseServerUrl } from '@Helpers/util';
import { DomoEmbedPartial } from '@Components/Partial/Product/Overview';

import { CopyLinkButton } from './PiracyOverviewPage';


const ReportPage = ({
  small,
  loading,
  mixpanel,
  location,
  authPagesConfig = null,
  customReports = null,
  categoryReports = null,
  cyclopsConfig = null,
  refreshAccessData,
  clearAccessData,
  companyId,
  accessToken = null,
  accessTokenExpiration = null,
}) => {
  const [embedToken, setEmbedToken] = React.useState(null);
  const [embedData, setEmbedData] = React.useState({
    embedId: null,
    embedTitle: null,
    applyCompanyFilter: null,
    pageTitle: null,
  });
  const { id } = useParams();

  React.useEffect(() => {
    const customEmbed = customReports.metadata.config.customReports.find(
      ({ embedUid }) => embedUid === id,
    );

    if (customEmbed) {
      setEmbedData({
        embedId: customEmbed.embedId,
        embedTitle: customEmbed.embedTitle,
        applyCompanyFilter: customEmbed.applyCompanyFilter,
        pageTitle: `Custom Report - ${customEmbed.embedTitle}`,
      });
    } else if (categoryReports) {
      const formattedReports = Object.entries(categoryReports).map(([key, value]) => {
        const values = Object.entries(value).map(([k, v]) => {
          const name = v.customReportName || Case.title(k);
          return { ...v, embedTitle: name, category: key };
        });

        return { [key]: values };
      });

      const allReports = formattedReports?.length > 0
        && formattedReports.map((category) => (Object.values(category)[0])).flat();

      const currentEmbed = allReports.find(({ embedUid }) => embedUid === id);
      const currentCategory = allReports.find(({ embedUid }) => embedUid === id)?.category;
      const displayCategory = Case.title(currentCategory.replace('Reports', ''));

      setEmbedData({
        embedId: currentEmbed.embedId,
        embedTitle: currentEmbed.embedTitle,
        applyCompanyFilter: currentEmbed.applyCompanyFilter,
        pageTitle: `${displayCategory} - ${currentEmbed.embedTitle}`,
      });
    }
  }, [customReports, categoryReports]);

  const fetchServerEmbedToken = async (useToken) => {
    const options = { method: 'GET' };
    let embedParams = `token=${useToken}&embedId=${embedData.embedId}`;

    if (embedData.applyCompanyFilter) {
      embedParams += `&companyId=${companyId}`;
    }

    const response = await fetch(`${baseServerUrl()}api/embedToken?${embedParams}`, options);
    const responseContent = await response.text();
    setEmbedToken(responseContent);
  };

  React.useEffect(() => {
    if (!accessToken || accessTokenExpiration - 60 > secondsSinceEpoch()) {
      refreshAccessData();
    }

    return () => {
      clearAccessData();
    };
  }, []);

  React.useEffect(() => {
    if (accessToken) {
      fetchServerEmbedToken(accessToken);
    }
  }, [accessToken]);

  const { primaryText, buttonHighlight } = authPagesConfig;

  return (
    <AuthNavWrapper
      smallBanner
      hideFooter
      small={small}
      mixpanel={mixpanel}
      location={location}
      authPagesConfig={authPagesConfig}
      customReports={customReports}
      cyclopsConfig={cyclopsConfig}
      bannerProps={{
        title: embedData.pageTitle || 'Loading report...',
        subTitle: 'Content protection hub',
        textColor: 'white',
        bannerChildren: (
          <Box
            direction="row"
            justify="center"
            align="center"
            gap="1rem"
          >
            <CopyLinkButton
              small={small}
              textColor={primaryText}
              highlightColor={buttonHighlight}
              mixpanel={mixpanel}
              location={location}
              pageTitle={embedData.pageTitle}
              embedId={embedData.embedId}
              applyFilter={embedData.applyCompanyFilter}
              companyId={companyId}
              renderKey="report"
            />
          </Box>
        ),
        bannerChildrenPosition: 'right',
      }}
    >
      <Seo />
      <Box flex background="#F1F2FC" pad={{ vertical: '1rem', horizontal: small ? '0.5rem' : '5vw' }} height="100%">
        <DomoEmbedPartial
          loading={loading}
          textColor={primaryText}
          highlightColor={buttonHighlight}
          embedId={embedData.embedId}
          embedToken={embedToken}
        />
      </Box>
    </AuthNavWrapper>
  );
};

function mapStateToProps(state) {
  return {
    accessToken: state.productEmbed.reportAccessToken,
    accessTokenExpiration: state.productEmbed.reportAccessTokenExpiration,
    loading: state.fetchLoader.dataLoading,
    companyId: state.auth.companyId,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    refreshAccessData: refreshReportAccessDataRequest,
    clearAccessData: clearReportAccessDataRequest,
  }, dispatch);
}

ReportPage.propTypes = {
  small: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  mixpanel: PropTypes.shape({
    track: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
  }).isRequired,
  authPagesConfig: PropTypes.shape({
    pageBg: PropTypes.string.isRequired,
    altComponentBg: PropTypes.string.isRequired,
    navBorder: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    hintText: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    focusHighlight: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    incrementText: PropTypes.string.isRequired,
    decrementText: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    iconHighlightColor: PropTypes.string.isRequired,
    constructionImage: PropTypes.string.isRequired,
  }),
  customReports: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      config: PropTypes.shape({
        companyId: PropTypes.number.isRequired,
        customReports: PropTypes.arrayOf(PropTypes.shape({
          embedId: PropTypes.string.isRequired,
          embedTitle: PropTypes.string.isRequired,
          embedUid: PropTypes.string.isRequired,
        })).isRequired,
      }).isRequired,
    }).isRequired,
  }),
  categoryReports: PropTypes.objectOf(PropTypes.objectOf(PropTypes.shape({
    embedId: PropTypes.string.isRequired,
    embedUid: PropTypes.string.isRequired,
    customReportName: PropTypes.string,
    applyCompanyFilter: PropTypes.bool,
  }))),
  cyclopsConfig: PropTypes.arrayOf(PropTypes.any),
  refreshAccessData: PropTypes.func.isRequired,
  clearAccessData: PropTypes.func.isRequired,
  accessToken: PropTypes.string,
  accessTokenExpiration: PropTypes.number,
  companyId: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withProductAuth(ReportPage));
